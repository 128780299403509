.buttonImage {
  width: 35px;
}
.menuList {
  width: fit-content !important;
  min-width: unset !important;
  color: #12022f !important;
  background: #ffdda9 !important;
}
.menuItem {
  background: #ffdda9 !important;
}

@media (max-width: 768px) {
  .buttonImage {
    width: 30px;
  }
}
