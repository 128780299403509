/* @src/components/Modal.module.css */

.darkBG {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 888;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.centered {
  position: fixed;
  z-index: 900;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 600px;
  height: auto;
  background: #470a71;
  color: white;
  z-index: 999;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.closeBtn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.modalContent {
  padding: 10px;
  padding-top: 80px;
  padding-bottom: 80px;
  font-size: 14px;
  text-align: center;
}

.modalContentWithoutCloseBtn {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 300px;
    height: auto;
  }
  .modalContent {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
