@use "../../../variables" as var;
.MainContainer {
  position: relative;
  max-width: 1100px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 70px 0;

  @media (max-width: var.$mobile-break-point) {
    width: calc(100% - 30px);
    padding: 50px 0;
  }
}
