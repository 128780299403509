.errorInput {
  color: rgb(223, 28, 28);
}

.tokenInput {
  padding-left: 40px !important;
}

.tokenIcon{
  width: 2rem;
  height: 2rem;
}