.JoinButtonBox {
  display: inline-block;
  border-radius: 28px !important;
  width: 160px;
  height: 46px;
  background: #ffdda9;
  user-select: none;

  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  line-height: 26px !important;
  text-align: center;
  letter-spacing: -0.36px;
  color: #12022f;
  cursor: pointer;
}

.outlinedButton {
  background: transparent !important;
  border: 1px solid #ffdda9;
}
.disabledButton {
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}
.dangerButton{
  background-color: #EC407A;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .JoinButtonBox {
    width: 200px;
    margin: 0 auto;
    font-size: 14px;
  }
}
