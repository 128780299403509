@use "../../variables" as var;

.container {
    flex-direction: column;
    padding: 0 20px;
}

.modalTitle {
    @include var.h2-text;
    align-self: flex-start;
}

.modalSubTitle {
    @include var.detail-text;
    align-self: flex-start;
    text-align: left;
    margin-bottom: 20px;
}

.sliderWrapper{
    padding: 20px 18px 30px;
}
.receiveWrapper{
    flex-direction: column;
    background-color: #280869;
    border-radius: 20px;
    padding: 20px;
}
.inputGroup {
    justify-content: space-between;
    flex-wrap: wrap;
}
.receiveText {
    @include var.detail-text;
}
.desc{
    @include var.desc-text;
    text-align: left;
    margin: 20px 0;
}

.metadatas{
    flex-direction: column;
    width: 100%;
}
.metadata {
    justify-content: space-between;
    margin: 5px 0;
}
.metaValue{
    font-weight: 600;
}
.priceValues {
    flex-direction: column;
}

.buttons {
    margin-top: 20px;
}

@media (max-width: var.$mobile-break-point) {
    .container {
        padding: 0 5px;
    }

    .tokenInput {
        width: 100%;
    }

    .modalSubTitle {
        font-size: 16px;
        line-height: 18px;
    }
    .tokenInputWrapper{
        margin: 10px 0;
    }

    
}