.headerContainer {
  background: linear-gradient(to right, #1d0458, #510b76);
}

.headerContent {
  max-width: 1240px;
  margin: 0 auto;
}

.headerFlex {
  width: "90%";
  align-items: center;
  justify-content: space-between;
  color: white;
  height: 105px;
  gap: 50px;
  font-family: "Rumiko Clear Demo", sans-serif;
  font-size: 16px;
  color: #ffffff;

  margin-bottom: -1px;
}

.headerLogoWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.headerLogo {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-size: cover;
  background: url("../../assets/image/logo.png");
  background-repeat: no-repeat;
}

.headerLogoText {
  display: inline-block;

  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}
.headerLinkContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.headerLink {
  /* margin-right: 30px; */
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.headerHamburger {
  display: none;
}
.headerLaunchBtn {
  display: flex;
  align-items: center;
  gap: 12px;
}
.headerSocialIcon {
  width: 30px;
  height: 30px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.headerTgIcon {
  background: url('../../assets/image/social-light/telegram.png');
}
.headerTwitterIcon {
  background: url('../../assets/image/social-light/Twitter.png');
}
.headerMediumIcon {
  background: url('../../assets/image/social-light/medium.png');
}

@media screen and (max-width: 768px) {
  .headerLinkContainer {
    display: none;
  }
  .headerFlex {
    justify-content: space-around;
    gap: 0 !important;
  }
  .headerSocialIcon{
    width: 20px;
    height: 20px;
  }
  .headerHamburger {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .headerHamburger .headerHamburgerImg {
    width: 40px;
    height: 40px;
    transition: all 1s ease;
  }
  .headerLaunchBtn {
    display: none;
  }
  .hambergerLink {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.355556px;
    color: #000000;
    margin: 30px 0;
  }
  .hambergerHide {
    right: -400px !important;
  }
  .hamburgerConnectButton {
    margin-top: 30px;
  }
}
