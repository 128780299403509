// $color-button-gradient: black;
// $color-title-gradient: black;
$mobile-break-point: 768px;
// $header-v-padding: 15px;
// $header-h-padding: 25px;
// $border-radius: 12px;

// $color-primary: #59d89d;
$color-primary-background: linear-gradient(to right, #1d0458, #510b76);
// $color-header-background: #212222;
// $color-card-background: #ededed;
// $color-card-background-dark: #7a8290;
// $color-red: #f23e74;

@mixin h1-text {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  color: #fcfcfd;
  @include respond(mobile) {
    @include h1-text-mobile;
  }
}

@mixin h1-text-mobile {
  font-size: 30px;
}

@mixin h2-text {
  font-family: "DM Sans";
  font-weight: 600;
  font-size: 40px;
  line-height: 64px;
  color: #fcfcfd;
  @include respond(mobile) {
    @include h2-text-mobile;
  }
}

@mixin h2-text-mobile {
  font-size: 18px;
  line-height: 44px;
}

@mixin detail-text {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 21px;
  line-height: 40px;
  color: #fcfcfd;
}

@mixin desc-text {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 20px;
  color: #bababa;
}

@mixin common-card {
  width: 100%;
  background: linear-gradient(
    263.04deg,
    rgba(130, 36, 187, 0.3) 0%,
    rgba(62, 18, 182, 0.3) 100%
  );
  border-radius: 23px;
  padding: 40px;
}

@mixin respond($breakpoint) {
  @if $breakpoint==mobile {
    @media only screen and (max-width: $mobile-break-point) {
      @content;
    }
  }
}
