@use "../../variables" as var;

.mining {
  width: 100%;
  background: var.$color-primary-background;
}

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  color: white;
  padding-bottom: 100px;
  max-width: 1240px;
}

.banner {
  position: relative;
  width: 90% !important;
  margin-top: 130px;
  margin-bottom: 50px;

  @include var.common-card;

  .bannerTitle {
    @include var.h1-text;
  }

  .bannerText {
    @include var.h2-text;
  }

  .img1 {
    position: absolute;
    width: 380px;
    height: 380px !important;
    bottom: -30px;
    right: -100px;
  }
}

.miningMain {
  position: relative;
  flex-wrap: wrap;
}

.miningSection {
  flex-direction: column;
  align-items: center;

  .miningTitle {
    @include var.h2-text;
    margin-bottom: 40px;
  }
}

.miningToken {
  text-align: center;
  width: 80%;
}

.tokenDesc {
  @include var.desc-text;
  line-height: 30px;
}

.pool {
  position: relative;
  @include var.common-card;
  width: 400px;
  padding-top: 100px;
  text-align: center;

  .poolTitle {
    @include var.h2-text;

    font-size: 28px;
  }

  .poolAmount {
    @include var.h1-text;
    color: #ffd9a0;
  }

  .poolTokenName {
    @include var.detail-text;
  }

  .poolDesc {
    @include var.desc-text;
    font-style: italic;
  }

  .img2 {
    position: absolute;
    width: 180px;
    height: 180px !important;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150%;
  }
}

.miningTitle {
  @include var.h2-text;
  margin-bottom: 40px;
}

.mineRigCard {
  @include var.detail-text;
  width: 100%;
  background-color: #741bae;
  border-radius: 20px;
  padding: 20px 0;
}

.interestTable {
  border: 1px solid #741bae;

  th,
  td {
    border: 1px solid #741bae;
    padding: 10px;
  }
}

.stakeCard {
  @include var.common-card;
  width: 400px;
  padding: 20px;

  .stakeInfo {
    @include var.desc-text;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .stakeAmount {
    @include var.detail-text;
  }
}

.stakeButton {
  color: #ff8d8d !important;
  border-color: #ff8d8d !important;
  width: 100%;
}

.modalTitle {
  @include var.h2-text;
}

.modalText {
  @include var.detail-text;
}

.holdingBonus {
  margin-top: -20px;
  font-size: 12px;
  font-weight: 600;
  text-align: justify;
}

@media (max-width: var.$mobile-break-point) {
  .container {
    padding-left: 2%;
    padding-right: 2%;

    gap: 20px;
  }
  .banner {
    padding: 20px;
  }

  .bannerTitle p {
    font-size: 25px;
  }

  .miningMain {
    width: 90%;
    gap: 20px;
  }
  .interestTableContainer {
    width: 100%;
  }
  .interestTable {
    width: 100%;
  }

  .banner {
    img {
      display: none;
    }

    margin-top: 50px;
  }

  .miningToken {
    width: 100%;
  }

  .pool {
    margin-top: 70px;
  }

  .stakeInfo {
    flex-wrap: wrap;
  }
}
