@use "../../variables"as var;

.invest {
  width: 100%;
  background: var.$color-primary-background;
}

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: white;
  padding-bottom: 100px;
  max-width: 1240px;
}

.banner {
  position: relative;
  margin-top: 20px;
  @include var.common-card;


  .bannerTitle {
    @include var.h1-text;
  }

  .bannerText {
    @include var.h2-text;
  }

  .img1 {
    position: absolute;
    width: 320px;
    height: 320px !important;
    bottom: -20px;
    right: -100px;
    height: 150%;
  }
}

.card {
  @include var.common-card;
  display: flex;
  padding: 26px;

  img {
    width: 50px;
    margin-right: 10px;
  }
}

.lpBalance {
  align-items: center;
}

.lpBtnGroup {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px 0;
  // &>div{
  //   margin: 5px;
  // }
}

.lpBtn {
  // margin-left: 20px;
}

.historyTitle {
  font-weight: 500;
  font-size: 24px;
  align-self: self-start;
}

.historyInfoFlex {
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.historyInfo {
  font-family: Poppins;
  justify-content: center;
  align-items: center;
  width: 32%;
  height: 100px;
  background: #741bae;
  border-radius: 20px;
}

.wideHistoryInfo {
  width: 48%;
  height: 120px;
}

.historyTable {
  width: 100%;

  tr,
  td,
  th {
    border: none !important;
    text-align: center;
    user-select: none;
  }

  td {
    padding: 10px 0;
  }
}

.firstTd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.claimBtn {
  margin-right: 10px;
}

.lpIcon {
  position: relative;
  width: 60px;
  height: 40px;

  &>img {
    position: absolute;
    top: 0;
    width: 70%;
    height: 100%;
  }

  &>img:first-child {
    left: 0;
  }

  &>img:last-child {
    right: 0;
  }
}

.desktopItemIcon {
  width: 30px;
  height: 20px;
  margin-right: 4px;
}

.lpAddStakeBtn {
  width: 14rem;
}

.usdsLpIcon {
  position: relative;
  width: 3rem;
  height: 2rem;

  img {
    width: 2rem !important;
    height: 2rem !important;
    position: absolute;
  }
  img:last-child {
    right: 0;
  }
}

.tokenIcon {
  width: 2rem !important;
  height: 2rem !important;
}

@media (max-width: var.$mobile-break-point) {
  .container {
    padding-top: 0;
    width: 90%;
    gap: 0;
  }

  .banner {
    height: 380px;

    .img1 {
      position: absolute;
      width: 220px;
      height: 220px !important;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    padding: 20px;
    margin-bottom: 20px;
  }

  .lpBtnGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    margin-top: 20px;
  }

  .lpBtn {
    width: 140px;
    height: 50px;
    margin: 0;

    &>div {
      line-height: 50px;
    }
  }

  .historyInfoFlex {
    gap: 0;
    justify-content: center;
  }

  .historyInfo {
    width: 90%;
    margin: 10px 0;
  }

  .claimAllBtn {
    width: 120px;
  }

  .historyCard {
    border: 1px solid #501E78;
    border-radius: 30px;
    padding: 10px;
    margin: 10px 0;
    width: 100%;

    display: flex;
    flex-direction: column;

    &>.title {
      display: flex;
      align-items: center;
    }

    .hisotryAttribute {
      justify-content: space-between;
      margin: 5px 0;
    }
  }

}