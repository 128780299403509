@use "../../variables" as var;

.profile {
  width: 100%;
  background: var.$color-primary-background;
}

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-bottom: 100px;
  max-width: 1240px;
  color: white;
}

.groupRewards {
  column-gap: 100px;
  row-gap: 20px;
}

.copyButton {
  display: inline-block;
  border-radius: 28px !important;
  width: 160px;
  height: 56px;
  background: #ffdda9;
  user-select: none;
  line-height: 56px;

  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.36px;
  color: #12022f;
  cursor: pointer;
}

.card {
  @include var.common-card;
  display: flex;
  padding: 26px;

  img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
}

.name {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  font-size: 21px;
  color: #8f8dff;
}

.address {
  @include var.detail-text;
}

.total {
  @include var.desc-text;
}

.tokenAmount {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px;
  color: #ffbe5b;
}

.usdcBalance {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 21px;
  line-height: 80px;
  color: #f4aeff;
}

.usdcButtonGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 30px;
  width: 40%;
}

.referalText {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 21px;
  line-height: 80px;
  color: #ff8d8d;
}

.tabTitleContainer {
  gap: 10px;
  align-self: flex-start;
  align-items: center;
}

.tabIcon {
  width: 80px;
  height: 80px;
}

.tabTitle {
  @include var.detail-text;
  cursor: pointer;
  color: #ffbe5b;
}

.choosenTabTitle {
  @include var.h2-text;
  color: white;
}

.historyInfoFlex {
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.historyInfo {
  font-family: Poppins;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 200px;
  height: 100px;
  background: #741bae;
  border-radius: 20px;
}
.historyTable {
  width: 100%;
  tr,
  td,
  th {
    border: none !important;
    text-align: center;
  }
}

.claimButton,
.tranferNftButton {
  height: 40px;
  div {
    line-height: 40px;
  }
}

.tranferNftButton {
  background-color: #8f8dff;
}
.historyItemEarned {
  @include var.detail-text;
  color: #ffbe5b;
  font-size: 16px;
}
.firstTd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 120px;
}
.claimAllReferralButton {
  font-size: 20px;
  cursor: pointer;
  font-weight: 700;
  color: #ffdda9;
}
.modalTitle {
  @include var.h2-text;
}
.modalText {
  @include var.detail-text;
}
@media (max-width: var.$mobile-break-point) {
  .container {
    width: 90%;
    gap: 0;
    &>div{
      margin-top: 24px;
    }
  }
  .tabTitleContainer {
    gap: 0;
    &>div{
      margin-left: 14px;
    }
  }
  .card {
    flex-direction: column;
  }
  .usdcButtonGroup {
    width: 100%;
    gap: 0;
    justify-content: left;
    &>:last-child{
      margin-top: 30px;
    }
  }
  .groupRewards {
    gap: 0;
    &>div{
      margin-top: 30px;
    }
    &>:first-child{
      margin-top: 0;
    }
  }
  .historyInfoFlex{
    gap: 0;
    &>div{
      margin: 6px;
    }
  }
  .historyInfo {
    width: 160px;
    gap: 0;
    &>div{
      margin-top: 14px;
    }
  }

  .referalText {
    width: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin: 20px 0;
  }
  .address {
    font-size: 16px;
    line-height: 24px;
  }
  .firstTd {
    width: 180px;
  }
  .historyInfoTitle {
    font-size: 14px;
  }

  .copyButton{
    margin: 0 auto;
  }
}
