.subscriptionContainer {
  position: relative;
  background-color: white;
  width: 100%;
  /* height: 450px; */
}

.subscriptionContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 600px; */
  color: black;
  z-index: 200;

  max-width: 1240px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.contactus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  column-gap: 10px;
}

.subscriptionLogo {
  width: 24px;
  height: 24px;
  background: url("../../assets/image/logo-dark.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.subscriptionLogoText {
  margin-left: 12px;
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.355556px;
  color: #391484;
}

.subscriptionLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
  height: 180px;
}

.subscriptionLinksGroup {
  width: 120px;
  height: 180px;
}

.subscriptionText {
  margin-top: 20px;
  font-family: "DM Sans";
  font-size: 14px;
  color: #594d6d;
}

.subscriptionText:first-child {
  margin-top: 0px;

  font-weight: 700;
}

.subscriptionLink {
  display: inline-block !important;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: "Rumiko Clear Demo";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.subscriptionInputLabel {
  font-family: "DM Sans";
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.355556px;
  color: #12022f;
}

.subscriptionInputRightElement {
  margin-top: 10px;
  width: 50px !important;
}

.subscriptionSocialFlex {
  display: flex;
  width: 350px;
  gap: 20px;
  justify-content: space-between;
}
.subscriptionSocialFlexImg {
  width: 30px;
}
.subscriptionSocialItem {
  width: 30px;
  height: 30px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.subscriptionSocialTwitter {
  background: url("../../assets/image/social-light/Twitter.png");
}

.subscriptionSocialDribble {
  background: url("../../assets/image/social-light/Dribbble.png");
}

.subscriptionSocialFacebook {
  background: url("../../assets/image/social-light/Facebook.png");
}

.subscriptionSocialLinkedin {
  background: url("../../assets/image/social-light/Linkedin.png");
}

.subscriptionSocialInvite {
  width: 100px;
  text-align: right;
  font-family: "DM Sans";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #594d6d;
}

.footerText {
  font-family: "DM Sans";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #594d6d;
  margin: 20px;
}

.subscriptionFlex {
  justify-content: space-between;
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .subscriptionContainer {
    /* height: 720px; */
  }

  .subscriptionFlex {
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
  }

  .subscriptionContent {
    height: 100%;
    padding: 0 5%;
  }

  .subscriptionSlogan {
    font-size: 30px;
  }

  .subscriptionText {
    font-size: 16px;
  }

  .subscriptionLink {
    font-size: 16px;
  }

  .footerFlex {
    margin-top: 20px;
    flex-direction: column-reverse;
  }

  .footerLogo {
    width: 110px;
    height: 30px;
  }

  .footerYoutube,
  .footerTwitter,
  .footerMedium,
  .footerInstagram {
    width: 20px;
    height: 15px;
  }

  .footerText {
    font-size: 10px;
  }

  .footerSpacer {
    display: none;
  }
  .copyrightWrap {
    flex-direction: column;
    padding-top: 30px;
  }
}
