@use "../../variables" as var;

.register {
  width: 100%;
  background: var.$color-primary-background;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  color: white;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.referal {
  width: 600px;
  height: 800px;

  & > img {
    width: 600px;
    height: 700px;
  }
}

.card {
  width: 600px;
}

.cardTitle {
  @include var.h1-text;
}

.cardText {
  @include var.desc-text;
}

.referralPaste {
  width: 80%;
}

@media (max-width: var.$mobile-break-point) {
  .container {
    width: 90%;
    padding-bottom: 50px;
  }
  .referal {
    width: 300px;
    height: 400px;

    & > img {
      width: 300px;
      height: 350px;
    }
  }
  .referralPaste {
    width: 100%;
  }
}
