@use "../../variables" as var;
.EarlyBird {
  width: 100%;
  background: var.$color-primary-background;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 70px;
  color: white;
  max-width: 900px;
  h1 {
    font-size: 48px;
    font-weight: 700;
  }
  h2 {
    font-size: 36px;
    font-weight: 700;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
  }
  p {
    font-weight: 700;
    line-height: 2;
  }
}

.card {
  position: relative;
  width: 100%;
  background: linear-gradient(
    263.04deg,
    rgba(130, 36, 187, 0.3) 0%,
    rgba(62, 18, 182, 0.3) 100%
  );
  border-radius: 23px;
  padding: 40px;
  &.firstCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img1 {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-10%, -50%);
      height: 150%;
    }
    .img2 {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(20%, -20%);
    }
  }
}

.purchaseWrap {
  width: 100%;
  display: flex;
  gap: 20px;
}

.actionWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  .unitPrice {
    color: #e19926;
  }
}

.inputWrap,
.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .desc {
    color: #bababa;
  }
}
.inputGroup {
  border-radius: 100px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  gap: 45px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 900;
  user-select: none;
  h2 {
    width: 40px;
    text-align: center;
  }
  .inputButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 24px;
    font-weight: 900;
    &:hover {
      cursor: pointer;
    }
  }
}

.benefitCard {
  flex: 1;
  background: rgba(130, 36, 187, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  img {
    margin-top: -15%;
    width: 35%;
    align-self: center;
  }
}

.invested {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #e19926;
  text-align: right;

  .leftTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    img {
      width: 50px;
      margin-right: 10px;
    }
  }
}

@media (max-width: var.$mobile-break-point) {
  .container {
    gap: 50px;
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-weight: 700;
      line-height: 2;
    }
  }
  .card {
    padding: 25px;
    &.firstCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img1 {
        position: relative;
        transform: none;
        width: 100%;
        max-width: 180px;
      }
      .img2 {
        display: none;
      }
    }
  }
  .purchaseWrap {
    flex-direction: column;
    gap: 50px;
  }
  .benefitCard {
    img {
      max-width: 200px;
      width: 45%;
    }
  }
}
