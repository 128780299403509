.carouselContainer {
  margin-top: 100px;
  position: relative;
  height: 600px;

  max-width: 1240px;
  overflow: hidden;
}

.carouselContent {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 1240px;
  z-index: 400;
}

.carouselImage {
  width: 160px;
  height: 160px;
  margin: auto;
}

.carouselImage img {
  display: inline-block;
  margin-top: 60px;
  width: 160px;
  height: 160px;
}

.carouselText {
  width: 100%;
  margin-top: 90px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #fcfcfd;
}
.carouselItemDetail {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #bababa;
}

.carouselItemContainer {
  width: 360px;
  height: 460px;
  background: rgba(103, 90, 90, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 20px;
}

.carouselItemArrow {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url("../../assets/image/rightArrow.png");
  transform: translateY(12px);
}

@media screen and (max-width: 768px) {
  .carouselItemContainer {
    width: 300px;
  }
}
