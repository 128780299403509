.bannerContainer {
  position: relative;
  background-color: #1a1a1a;
  width: 100%;
  height: 800px;
  padding: 80px 5%;
  background: linear-gradient(to right, #1d0458, #510b76);
}
.bannerContent {
  position: relative;
  max-width: 1240px;
  margin: auto;
}

.bannerBg1 {
  position: absolute;
  top: 100px;
  right: 0px;
  width: 600px;
  height: 600px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url("../../assets/image/banner/wallet.png");
}

.bannerSlogan {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -0.02em;
  color: #fcfcfd;
}

.bannerText {
  margin-top: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #bababa;
}

.bannerSocialContainer {
  margin-top: 30px;
  width: 200px;
  gap: 20px;
  display: flex;
}

.bannerSocialItem {
  width: 30px;
  height: 30px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.bannerSocialTwitter {
  background: url("../../assets/image/social/twitter.png");
}

.bannerSocialWhatsapp {
  background: url("../../assets/image/social/whatsapp.png");
}

.bannerSocialFacebook {
  background: url("../../assets/image/social/facebook.png");
}

.bannerSocialSettle {
  background: url("../../assets/image/social/settle.png");
}

.goldenLabel {
  margin-top: 0 !important;
  /* margin-top: 30px; */
  font-family: "Poppins";
  font-size: 16px;
  /* line-height: 24px; */
  color: #ffd9a0;
}

.bannerDownArrow {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url("../../assets/image/downArrow.png");
  transform: translateY(7px);
}

@media screen and (max-width: 768px) {
  .bannerSlogan {
    font-size: 30px;
    line-height: 56px;
  }

  .bannerText {
    font-size: 16px;
    line-height: 24px;
  }

  .bannerContent {
    margin-top: 200px;
  }

  .bannerBg1 {
    width: 100%;
    top: -300px;
  }

  .bannerContainer {
    height: 700px;
  }
  .earlyBirdWrap {
    flex-direction: column;
    align-items: flex-start !important;
    position: relative;
    z-index: 1000;
  }
}
