@use "../../variables" as var;
.modalTitle {
  @include var.h2-text;
}

.detailText {
  @include var.desc-text;
  margin: 1rem 0;
}

.inputError {
  color: red !important;
}