@use "../../variables" as var;

.container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.modalTitle {
    @include var.h2-text;
    align-self: flex-start;
}

.modalSubTitle {
    @include var.detail-text;
    align-self: flex-start;
    text-align: left;
    margin-bottom: 20px;
}

.inputGroup {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.tokenInput {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}
.balance{
    margin: 6px;
}

.apys {
    width: 100%;
    justify-content: space-between;
    margin: 20px 0;
}

.apy {
    @include var.common-card;
    padding: 10px;
    margin: 10px;
    background-color: #491CAA;
    cursor: pointer;
    user-select: none;
}

.highlightApy {
    background-color: #7B46A5;
}

.position {
    border-radius: 10px;
    border: 1px dashed #7B46A5;
    width: 100%;
    padding: 10px;
    flex-direction: column;

    .positionTitle {
        @include var.detail-text;
        align-self: flex-start;
        font-weight: 600;;
    }

    .positionDetailFlex {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .lpPosition1 {
        width: 200px;
        justify-content: space-between;

    }

    .positionLogo {
        width: 60px;
        background: url('../../assets/image/stake/lp-logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .positionDetail {
        flex-direction: column;
        justify-content: space-between;
        @include var.detail-text;
        align-self: flex-start;
        font-weight: 600;
    }

    .lpPosition2 {
        flex-direction: column;
        align-items: flex-end;
        width: 200px;
        justify-content: space-between;

        .positionLabel {
            @include var.desc-text;
        }

        .positionValue {
            margin-bottom: 10px;
        }
    }
}

.buttons {
    margin-top: 20px;
}

@media (max-width: var.$mobile-break-point) {
    .container {
        padding: 0 5px;
    }

    .tokenInput {
        width: 100%;
    }

    .modalSubTitle {
        font-size: 16px;
        line-height: 18px;
    }

    .apy {
        margin: 2px;
        font-size: 12px;
    }

    .position {
        padding: 0 10px;
    }
    .lpPosition2 {
        align-items: flex-start !important;
    }
}