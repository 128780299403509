.researchContainer {
  position: relative;
  width: 100%;
  height: 1000px;
  background: linear-gradient(to right, #1d0458, #510b76);
  margin-top: -1px;
}

.researchContent {
  max-width: 1240px;
  margin: auto;
}

.researchUpperFlex {
  justify-content: "space-between";
}

.researchSlogan {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -0.02em;

  color: #fcfcfd;
}

.researchText {
  margin-top: 30px;
  font-family: "rumiko-clear-demo", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: black;
  width: 40%;
}

.researchSlideStartIndexer,
.researchSlideCurrentIndexer {
  position: absolute;
  top: -10px;
  width: 20px;
  height: 20px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  z-index: 400;
}

.researchSlideStartIndexer {
}

.researchSlideCurrentIndexer {
  top: -15px;
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

@media screen and (max-width: 768px) {
  .researchContainer {
    padding: 0 5%;
    height: 900px;
  }

  .researchUpperFlex {
    flex-wrap: wrap;
  }

  .researchSlogan {
    font-size: 30px;
  }

  .researchText {
    font-size: 18px;
    width: 100%;
  }

  .researchBg {
    height: 300px;
  }

  .researchSlideCurrentIndexer {
    width: 20px;
    height: 20px;
  }

  .researchSlideStartIndexer {
    width: 15px;
    height: 15px;
    top: -7px;
  }

  .researchSlideCurrentIndexer {
    top: -10px;
    width: 20px;
    height: 20px;
  }

  .researchSlideCurrentIndexer0 {
    left: 5%;
  }

  .researchSlideCurrentIndexer1 {
    left: 50%;
  }

  .researchSlideCurrentIndexer2 {
    left: 95%;
  }
}
