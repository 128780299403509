.partnershipContainer {
  position: relative;
  width: 100%;
  height: 600px;
  padding: 0px 5%;
  margin-top: -1px;
  background: linear-gradient(to right, #1d0458, #510b76);
}
.partnershipContent {
  position: relative;
  max-width: 1240px;
  margin: auto;

  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.partnershipTextContainer {
  max-width: 400px;
}

.partnershipItemContainer {
  flex: 1;
  /* max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; */
}

.partnershipSlogan {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 64px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -2px;
  color: #ffffff;
}

.partnershipText {
  margin-top: 40px;
  font-family: "DM Sans";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #bababa;
}

.partnershipSocialContainer {
  margin-top: 30px;
  width: 200px;
  gap: 20px;
  display: flex;
}

.partnershipSocialTwitter {
  width: 30px;
  height: 30px;
  background-size: contain !important;
  background: url("../../assets/image/social/twitter.png");
  background-repeat: no-repeat !important;
}

.goldenLabel {
  margin-top: 30px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #ffd9a0;
}

.partnershipDownArrow {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url("../../assets/image/downArrow.png");
  transform: translateY(7px);
}
.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  // img {
  //   height: 100%;
  // }
}

@media screen and (max-width: 768px) {
  .partnershipContainer {
    height: 800px;
  }
  .partnershipSlogan {
    font-size: 30px;
    line-height: 56px;
  }

  .partnershipText {
    font-size: 16px;
    line-height: 24px;
  }

  .partnershipContent {
    flex-direction: column;
    align-items: center;
  }

  .partnershipText {
    text-align: center;
    margin-bottom: 40px;
  }
  .partnershipItemContainer {
    order: 10;
  }
}
