@use "../../variables"as var;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 16rem;
  height: 4rem;
  border-radius: 3.0625rem;
  border: 1px solid #FFF;
  background-color: transparent;

  padding: 0 1rem;
}

.item {
  z-index: 2;
  padding: 0 1rem;
}

.switcherBg {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 8.5rem;
  height: 4rem;
  border-radius: 3.0625rem;

  transition: all 0.1s ease-in-out;
}

.isSwitched {
  left: 8rem;
  right: 0;
}

.isBlack {
  color: black;
}

@media (max-width: var.$mobile-break-point) {
  .container {
    margin: 1rem 0 2rem 0;
  }
}