@use "../../variables" as var;

.container {
  @include var.common-card;
  border-radius: 1.5rem;
  background: linear-gradient(180deg, #4E1081 0%, #9F12B6 100%);
  margin-bottom: 30px;
  padding: 20px 10px;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}

.x3Logo{
  width: 150px;
  height: 150px;
  margin-right: 20px;
  background: url('../../assets/image/invest/x3.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.detailContainer {
  width: 350px;
  flex-direction: column;
}

.title {
  font-family: 'gotham-black', sans-serif;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.05625rem;
  background: linear-gradient(180deg, #6F08BF 0%, #D225B7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.detail {
  color: #B36CED;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.02rem;
  line-height: 120%;
  margin-bottom: 10px;
}

.strong {
  color: #FFBE5B;
}

@media screen and (max-width: 768px) {
  .container{
    justify-content: center;
  }
}