.ecosystemContainer {
  position: relative;
  width: 100%;
  height: 800px;
  padding: 100px 5%;
  margin-top: -1px;
  background: linear-gradient(to right, #1d0458, #510b76);
}
.ecosystemContent {
  position: relative;
  max-width: 1240px;
  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.ecosystemTextContainer {
  max-width: 400px;
  order: 2;
}

.ecosystemItemContainer {
  flex: 1;
  /* max-width: 700px; */
  /* display: flex;
  flex-wrap: wrap;
  gap: 20px; */
}

.ecosystemSlogan {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 64px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -2px;
  color: #ffffff;
}

.ecosystemText {
  margin-top: 40px;
  font-family: "DM Sans";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #bababa;
}

.ecosystemSocialContainer {
  margin-top: 30px;
  width: 200px;
  gap: 20px;
  display: flex;
}

.ecosystemSocialTwitter {
  width: 30px;
  height: 30px;
  background-size: contain !important;
  background: url("../../assets/image/social/twitter.png");
  background-repeat: no-repeat !important;
}

.goldenLabel {
  margin-top: 30px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #ffd9a0;
}

.ecosystemDownArrow {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url("../../assets/image/downArrow.png");
  transform: translateY(7px);
}
.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  // img {
  //   height: 100%;
  // }
}

@media screen and (max-width: 768px) {
  .ecosystemContainer {
    height: 900px;
  }
  .ecosystemSlogan {
    font-size: 30px;
    line-height: 56px;
  }

  .ecosystemText {
    font-size: 16px;
    line-height: 24px;
  }

  .ecosystemContent {
    flex-direction: column;
    align-items: center;
  }

  .ecosystemText {
    text-align: center;
    margin-bottom: 40px;
  }
  .ecosystemItemContainer {
    order: 10;
  }
}
