.clientsContainer {
  position: relative;
  background: linear-gradient(to right, #1d0458, #510b76);
  width: 100%;
  height: 800px;
  margin-top: -1px;
}

.clientsContent {
  margin: auto;
  height: 800px;
  color: white;
  z-index: 200;
  max-width: 1240px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.clientsLeft {
  width: 550px;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clientsLeftImg {
  width: 500px;
  height: 500px;
}

.clientsTitle {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 64px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.clientsText {
  font-family: "Poppins";
  line-height: 24px;
  color: #bababa;
}
.clientGoldenLabel {
  font-family: "Poppins";
  line-height: 24px;
  color: #ffd9a0;
}
.clientKeyIcon {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url("../../assets/image/clients/key.png");
  transform: translateY(7px);
}
.clientsRight {
  width: 550px;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clientsItemContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 220px;
}
.clientsItemSlogan {
  font-weight: 700;
}
.clientsItemText {
  margin-top: 20px;
  flex-grow: 2;
  max-width: 270px;
}

.clientsItemImg {
  width: 220px;
  height: 220px;
  background: #ddcbe7;
  border-radius: 16px;
  align-self: flex-end;
}

.clientsItemImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clientsItemComing,
.clientsItemText {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  color: #a8a8a8;
}
@media screen and (max-width: 768px) {
  .clientsContainer {
    height: 2000px;
  }

  .clientsContent {
    padding: 0 5%;
    height: 1600px;
    justify-content: center;
  }

  .clientsLeft {
    text-align: center;
    height: 600px;
  }
  .clientsLeftImg {
    width: 300px;
    height: 300px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .clientsItemContainer {
    flex-wrap: nowrap;
    height: 400px;
    align-items: center;
    gap: 10px;
  }
  .clientsItemImg {
    margin: auto;
    order: 0;
  }
  .clientsItemSlogan {
    order: 1;
  }
  .clientsItemText {
    order: 1;
    text-align: center;
  }
  .clientsItemGap {
    order: 3;
  }
  .clientsItemComing {
    order: 4;
  }
}
