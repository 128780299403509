@use "../../variables" as var;

.invest {
  width: 100%;
  background: var.$color-primary-background;
}

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 120px;
  color: white;
  padding-bottom: 100px;
  max-width: 1240px;
}

.banner {
  width: 90% !important;
  position: relative;
  margin-top: 130px;
  @include var.common-card;

  padding-left: 350px;

  .bannerTitle {
    @include var.h1-text;
  }

  .bannerText {
    @include var.h2-text;
  }

  .img1 {
    position: absolute;
    width: 380px;
    height: 380px !important;
    bottom: -30px;
    left: -100px;
    height: 150%;
  }
}

.investMain {
  position: relative;
  flex-wrap: wrap;
  column-gap: 10px;
  // FIX: some browser don't know this attribute, replace with margin
  // row-gap: 180px; 
}

.investSection {
  flex-direction: column;
  align-items: center;
  max-width: 600px;

  .investTitle {
    @include var.h2-text;
    margin-bottom: 40px;
  }
}

.investToken {
  text-align: center;
}

.tokenDesc {
  @include var.desc-text;
  line-height: 30px;
}

.plus {
  font-size: 30px;
  font-weight: 500;
  margin: 0 20px;
}

.rules {
  position: relative;
  @include var.common-card;
  width: 400px;
  padding-top: 100px;
  margin-left: 30px;

  .rulesTitle {
    @include var.h2-text;
    font-size: 28px;
  }

  .rulesText {
    @include var.detail-text;
  }

  .img2 {
    position: absolute;
    width: 180px;
    height: 180px !important;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    // height: 150%;
  }
}

.nftModal {
  // background: transparent !important;
  width: 400px !important;
  max-width: 100%;
}
.modalDivier {
  width: 100%;
  border: 1px dashed white;
}

.modalDetail {
  width: 100%;
  @include var.detail-text;
  font-size: 14px;

  > div {
    padding: 0 10px;
    justify-content: space-between;
  }
}

.investInput {
  padding-left: 40px !important;
}
.inputError {
  color: red !important;
}
.checkNftBtn {
  height: 40px;
  div {
    line-height: 40px;
  }
}
@media (max-width: var.$mobile-break-point) {
  .container {
    width: 90%;
    gap: 20px;
  }

  .banner {
    img {
      display: none;
    }

    padding: 20px;
    margin: 50px 0;
  }

  .investSection {
    width: 90%;
  }

  .plus {
    display: none;
  }

  .rules {
    width: 90%;
    padding: 20px;
    margin-top: 180px;
    margin-left: 0px;
  }

  .rulesTitle {
    margin-top: 100px;
  }
}
